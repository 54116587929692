import { Controller } from "@hotwired/stimulus"
const invisibleClass = 'invisible'
const fadeInClass = "animate-slideIn"
const fadeOutClass = "animate-slideOut"
const grayClass = "bg-gray-300"
const opacityClass = "bg-opacity-80"
const pointerEventsNoneClass = "pointer-events-none"
export default class extends Controller {
  static targets = ['list', 'menuMark']

  connect() {
  }

  toggleMenu() {
    const listElement = this.listTarget;
    const menuMarkElement = this.menuMarkTarget;
    if (listElement.classList.contains(invisibleClass)) {
      menuMarkElement.classList.toggle(grayClass);
      menuMarkElement.classList.toggle(opacityClass);
      listElement.classList.toggle(pointerEventsNoneClass);
      listElement.classList.add(fadeInClass);
      listElement.classList.remove(invisibleClass);
    } else {
      menuMarkElement.classList.toggle(grayClass);
      menuMarkElement.classList.toggle(opacityClass);
      listElement.classList.toggle(pointerEventsNoneClass);
      listElement.classList.toggle(fadeInClass);
      listElement.classList.toggle(fadeOutClass);
    }

    return;
  }

  closeMenu() {
    const listElement = this.listTarget
    const menuMarkElement = this.menuMarkTarget;

    if(listElement.classList.contains(invisibleClass)){
      return
    }

    menuMarkElement.classList.toggle(grayClass);
    menuMarkElement.classList.toggle(opacityClass);
    listElement.classList.toggle(pointerEventsNoneClass);
    listElement.classList.toggle(fadeInClass);
    listElement.classList.toggle(fadeOutClass);
    return;
  }
}
